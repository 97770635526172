var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "regenera" },
    [
      _c(
        "div",
        { staticClass: "mt-5", attrs: { role: "group" } },
        [
          [
            _c(
              "b-button",
              {
                staticClass: "button btn-blue-dark ml-0",
                attrs: { variant: "outline-primary" },
                on: { click: _vm.newProgramConfiguration }
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("admin.bussiness.agregarproductoregenera")) +
                      " +"
                  )
                ])
              ]
            )
          ]
        ],
        2
      ),
      _c(
        "div",
        [
          _vm.configRol.administrador.pestana.auth_programs.acciones.listar
            ? _c(
                "b-row",
                { staticClass: "mt-3 mx-3" },
                [
                  _c(
                    "b-col",
                    { staticClass: "tableRequest" },
                    [
                      _c("b-table", {
                        attrs: {
                          items: _vm.productsByCategories,
                          fields: _vm.fields,
                          stacked: "md",
                          "show-empty": "",
                          borderless: "",
                          "thead-tr-class": "table-head-none",
                          busy: _vm.isBusy
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(name)",
                              fn: function(row) {
                                return [
                                  _c("h5", { staticClass: "ml-3" }, [
                                    _vm._v(_vm._s(row.item.categoria))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "table-busy",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center text-primary my-2"
                                    },
                                    [
                                      _c("b-spinner", {
                                        staticClass: "align-middle"
                                      }),
                                      _c("strong", [_vm._v("Loading...")])
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "cell(actions)",
                              fn: function(row) {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn button-details",
                                      attrs: { size: "sm" },
                                      on: { click: row.toggleDetails }
                                    },
                                    [
                                      row.detailsShowing
                                        ? _c("b-icon", {
                                            attrs: {
                                              icon: "chevron-up",
                                              "aria-hidden": "true"
                                            }
                                          })
                                        : _c("b-icon", {
                                            attrs: {
                                              icon: "chevron-down",
                                              "aria-hidden": "true"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "row-details",
                              fn: function(row) {
                                return [
                                  !row.item
                                    ? _c("p", { staticClass: "text-center" }, [
                                        _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                row.item.error ||
                                                  _vm.$t("admin.auth.noData")
                                              )
                                          )
                                        ])
                                      ])
                                    : _vm._e(),
                                  _c("b-table", {
                                    staticClass: "tableFirst",
                                    attrs: {
                                      items: row.item.productos,
                                      fields: _vm.fieldsGroupers,
                                      "filter-included-fields": _vm.filterOn,
                                      stacked: "md",
                                      "show-empty": "",
                                      hover: "",
                                      "table-variant": "light",
                                      "sort-icon-left": "",
                                      "select-mode": "single",
                                      selectable: "",
                                      borderless: "",
                                      "tbody-tr-class": "table-users",
                                      busy: _vm.isLoadingGroupers
                                    },
                                    on: {
                                      "row-selected": _vm.onRowselectedGrouper
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(nombre)",
                                          fn: function(row) {
                                            return [
                                              _c("b", [
                                                _vm._v(_vm._s(row.value))
                                              ])
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(bitRegenera)",
                                          fn: function(row) {
                                            return [
                                              _c("b-form-checkbox", {
                                                attrs: {
                                                  name: "check-button",
                                                  switch: ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.changeStatusBitRegenera(
                                                      row.item
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: row.item.bitRegenera,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      row.item,
                                                      "bitRegenera",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.item.bitRegenera"
                                                }
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(status)",
                                          fn: function(row) {
                                            return [
                                              _vm.configRol.administrador
                                                .pestana.medicines.acciones
                                                .eliminarAgrupador
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "mr-2",
                                                      class: {
                                                        "mr-2 bg-success":
                                                          row.item.status === 1,
                                                        "mr-2 bg-danger":
                                                          row.item.status === 2
                                                      },
                                                      attrs: {
                                                        size: "sm",
                                                        disabled:
                                                          _vm.isLoadingChangeStatus
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeStatus(
                                                            row.item,
                                                            !row.value
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.item.status ===
                                                              1
                                                              ? _vm.$t(
                                                                  "admin.bussiness.estatusregeneraActivo"
                                                                )
                                                              : _vm.$t(
                                                                  "admin.bussiness.estatusregeneraInactivo"
                                                                )
                                                          ) +
                                                          " "
                                                      ),
                                                      _vm.isLoadingChangeStatus
                                                        ? _c("b-spinner", {
                                                            staticClass:
                                                              "align-middle",
                                                            attrs: { small: "" }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "table-busy",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-center text-primary my-2"
                                                },
                                                [
                                                  _c("b-spinner", {
                                                    staticClass: "align-middle"
                                                  }),
                                                  _c("strong", [
                                                    _vm._v("Loading...")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4247620642
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("ModalAgregarProductoRegenera", {
        attrs: {
          modalShow: _vm.modalShowPrograms,
          isEdit: _vm.isEditConfig,
          configurationForm: _vm.configurationForm,
          isClone: _vm.isCloneConfig
        },
        on: { closeModal: _vm.closeModalPrograms }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }