<template>
  <div class="regenera">
    <div role="group" class="mt-5">
      <template>
        <b-button
          variant="outline-primary"
          class="button btn-blue-dark ml-0"
          @click="newProgramConfiguration"
        >
          <span>{{ $t("admin.bussiness.agregarproductoregenera") }} +</span>
        </b-button>
      </template>
      
    </div>

    <div>
      <b-row class="mt-3 mx-3" v-if="configRol.administrador.pestana.auth_programs.acciones.listar">
        <b-col class="tableRequest">
          <!-- Main table element -->
          <b-table
            :items="productsByCategories"
            :fields="fields"
            stacked="md"
            show-empty
            borderless
            thead-tr-class="table-head-none"
            :busy="isBusy"
            
          >
          <!-- ESTE ES EL TITULO DE categoria -->
            <template #cell(name)="row">
              <!-- <h5 class="ml-3">{{ row.item.categoria }}</h5> -->
              <h5 class="ml-3">{{ row.item.categoria }}</h5>
            </template>

            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(actions)="row">
              <b-button
                size="sm"
                class="btn button-details"
                @click="row.toggleDetails"
              >
                <b-icon
                  icon="chevron-up"
                  aria-hidden="true"
                  v-if="row.detailsShowing"
                ></b-icon>
                <b-icon icon="chevron-down" aria-hidden="true" v-else></b-icon>
              </b-button>
            </template>

            <template #row-details="row">
              <p v-if="!row.item" class="text-center">
                <b> {{ row.item.error || $t(`admin.auth.noData`) }}</b>
              </p>
              <b-table
              :items="row.item.productos"
              :fields="fieldsGroupers"
              :filter-included-fields="filterOn"
              stacked="md"
              show-empty
              hover
              table-variant="light"
              class="tableFirst"
              sort-icon-left
              select-mode="single"
              selectable
              borderless
              tbody-tr-class="table-users"
              @row-selected="onRowselectedGrouper"
              :busy="isLoadingGroupers" >
                <template #cell(nombre)="row">
                  <b>{{ row.value }}</b>
                </template>
                <template #cell(bitRegenera)="row">
                <b-form-checkbox
                    v-model="row.item.bitRegenera"
                    @change="changeStatusBitRegenera(row.item)"
                    name="check-button"
                    switch />
                </template>
                <template #cell(status)="row">
                  <b-button
                    size="sm"
                    @click="changeStatus(row.item, !row.value)"
                    class="mr-2"
                    :class="{ 'mr-2 bg-success': row.item.status === 1, 'mr-2 bg-danger': row.item.status === 2 }"
                    :disabled="isLoadingChangeStatus"
                    v-if="configRol.administrador.pestana.medicines.acciones.eliminarAgrupador"
                  >
                  {{ row.item.status === 1 ?  $t("admin.bussiness.estatusregeneraActivo")  :  $t("admin.bussiness.estatusregeneraInactivo") }}
                    <b-spinner
                      v-if="isLoadingChangeStatus"
                      class="align-middle"
                      small
                    ></b-spinner>
                  </b-button>
                </template>
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>
  
    <ModalAgregarProductoRegenera
      :modalShow="modalShowPrograms"
      @closeModal="closeModalPrograms"
      :isEdit="isEditConfig"
      :configurationForm="configurationForm"
      :isClone="isCloneConfig"
    />
  </div>
  
  
</template>

<script>
import { API_ROOT } from "../../Constants/Api";
import { AlertForm, ICON } from "../../helpers/Alert";
import { ApiRequest } from '../../helpers/ApiRequest';
import ModalAgregarProductoRegenera from "./BussinessAndBranch/ModalAgregarProductoRegenera.vue";

export default {
  name: "RegeneraProducts",
  components: { ModalAgregarProductoRegenera},
  data: () => ({
    fields: [
      { key: "name", label: "Name", sortable: true, sortDirection: "desc" },
      { key: "actions", label: "" },
    ],
    selected: [],
    isBusy: false,

    productsByCategories: [],
    codeIntelisis: "",
    isLoadingGroupers: false,
    isLoadingSaveGrouper: false,
    isLoadingChangeStatus: false,
    tableGroupers: [],
    selectedGrouper: [],
    configRol: {},
    productosregeneraActivo:[],
    productosregeneraInactivo:[],
    isLoadingBussinessList: false,
    itemsBussiness: [],
    filterBussiness: null,
    filterOn: [],
    checked: false,
    modalShowPrograms: false,
    isEditConfig: false,
    configurationForm: null,
    isCloneConfig: false,
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if (
      this.configRol.administrador.pestana.adminSucursales.permiso === false
    ) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.bussiness.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    }
  },
  async mounted() {
    await this.updateTableGroupers();
  },
  computed: {
    fieldsGroupers() {
      return [
        {
          key: "nombre",
          label: this.$i18n.t("admin.bussiness.name"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "bitRegenera",
          label: 'Regenera',
          sortable: true,
          sortDirection: "desc",
        },
       {
          key: "idProducto",
          label: this.$i18n.t("admin.bussiness.code"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "status",
          label: this.$i18n.t("admin.bussiness.estatus"),
          sortable: true,
          sortDirection: "desc",
        },
      ];
    },
  },
  methods: {
    async getList() {
    
      try {
        let  data = await ApiRequest.get(
          `${API_ROOT}productos/productos${this.$i18n.locale === 'en' ? '' : 'MX'}`
        );
        this.selectedGrouper = data.data
      } catch (err) {
        AlertForm(`Error Code: ${err.status}, err.statusText, ICON.ERROR`);
      }
    },
    async getGrupersList() {
      let activeGroupers = [];
      let inactiveGroupers = [];
      try {
        const data = await ApiRequest.get(`${API_ROOT}productos/productosRegenera`);

        activeGroupers = data.productos.filter((el) => {
          return el.status == 1;
        });
               
        inactiveGroupers = data.productos.filter((el) => {
          return el.status == 2;
        });

      } catch (err) {
         if(err.status !== "Data Failure") {
           AlertForm(`Error Code: ${err.status}, err.statusText, ICON.ERROR`);
         }
      }


      this.tableGroupers = [...activeGroupers, ...inactiveGroupers];
      this.tableGroupers = this.tableGroupers.sort((a, b) => a.nombre.localeCompare(b.nombre))


      const productsByCategories = [];

      for (const product of this.tableGroupers) {
        const foundCategory = productsByCategories.find(group => group.categoria.toUpperCase() === product.categoria.toUpperCase());

        if (foundCategory) {
          foundCategory.productos.push(product);
        } else {
          productsByCategories.push({
            categoria: product.categoria.toUpperCase(),
            productos: [product]
          });
        }
      }

      this.productsByCategories = productsByCategories.sort((a, b) => a.categoria.localeCompare(b.categoria));

    },
    async updateTableGroupers() {
      this.isLoadingGroupers = true;
      await this.getGrupersList();
      this.isLoadingGroupers = false;
    },
    onRowselectedGrouper(items) {
      this.selectedGrouper = items;
      if (items.length) {
        const [info] = items;

        this.inputGrouper = {
          nombre: info.nombre,
          dosis: info.dosis,
          medicamento: info.medicamento,
          codigosInt: info.codigo_intelisis,
        };
        this.codeIntelisis = info.codigo_intelisis.join(", ");
      } else {
        this.inputGrouper = {
          nombre: "",
          dosis: "",
          medicamento: "",
          codigosInt: "",
        };
        this.codeIntelisis = "";
      }
    },
   async changeStatusBitRegenera(item) {
      const { idProducto, bitRegenera }=item;

      try {
        await ApiRequest.put(`${API_ROOT}productos/updateProductosRegenera`, {
          idProducto, 
          bit: bitRegenera
        });
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.bussiness.errorUpdateProgram"), ICON.ERROR);
      }
    },
    newProgramConfiguration() {
      this.modalShowPrograms = true;
      this.configurationForm = null;
      this.isEditConfig = false;
      this.isCloneConfig = false;  
    },
    async closeModalPrograms() {
      this.modalShowPrograms = false;
      this.configurationForm = null;
      await this.getGrupersList()
    },
  },
};

</script>

<style scoped>
@import "../../assets/css/global.css";

.regenera {
  min-height: 100vh;
}
.regenera /deep/ .headerClass {
  color: var(--gray-color);
  font-size: 0.8rem;
}

/** FORM USER */
.form-user {
  font-weight: bold;
}
#input-name.form-control,
#input-email.form-control {
  color: #333232;
  background: #e9e8e8c9;
  border: none;
}
/**menu dropdown */
#dropdown-form /deep/ .form-user {
  width: 400px;
}
#dropdown-form /deep/ .b-dropdown-text {
  color: #707070;
  font-weight: bolder;
}
#dropdown-form input.form-input-style,
#dropdown-form /deep/ .select {
  font-weight: bold;
  border: none;
  border-radius: 0%;
  border-bottom: 1px solid #7070708a;
  margin: 15px 0;
}
#dropdown-form /deep/ .select {
  color: #707070c4;
}
#dropdown-form input.form-input-style::placeholder {
  color: #7070709d;
}
#dropdown-form input.form-input-style:focus {
  outline: none;
}
/**BUTTON STYLES */
.text-align-end {
  text-align: end;
}
#dropdown-form .buttons-dropdown {
  display: flex;
}
#dropdown-form .btn-blue-dark,
#dropdown-form .btn-blue-dark-outline {
  padding: 5px 10px;
  margin: 5px;
}
/**Table styles */
.tableFirst /deep/ .table-users {
  border-bottom: 1px solid rgb(160, 158, 158);
}
</style>