<template>
  <div>
    <b-modal
      size="xl"
      scrollable
      v-model="modalShow"
      :title="this.$i18n.t('admin.bussiness.name')"
      no-close-on-backdrop
      hide-header-close
    >
      <b-row>
        <b-col cols="4">
          <label class="mt-3 font-weight-bold d-block">{{ $t('admin.role.country') }} *</label>
          <b-form-select
            v-model="formPrograms.pais"
            :options="optionsCountry"
            size="sm"
            class="mt-0 select"
            :disabled="isEdit || isClone"
          >
            <template #first>
              <b-form-select-option :value="0" disabled
                >-- {{ $t('admin.role.selectCountry') }} --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>

      <hr />
         <b-row>
        <b-col cols="4">
          <label class="mt-3 font-weight-bold d-block">{{ $t('admin.bussiness.Category') }} *</label>
          <b-form-select
            v-model="formPrograms.categorias"
            :options="optionsCategories"
            size="sm"
            class="mt-0 select"
            :disabled="isEdit || isClone"
          >
            <template #first>
              <b-form-select-option :value="0" disabled
                >-- {{ $t('admin.bussiness.selectCategory') }} --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <hr />

      <section>
        <hr />
        <h4 class="mt-4">{{ $t('admin.bussiness.name') }}</h4>
        <b-card :header="this.$i18n.t('admin.bussiness.product')" class="text-center w-50 mt-3">
          <b-card-text>
            <div>
              <div class="groupSearch mb-3">
                <b-input-group size="sm" class="inputSearch">
                  <b-form-input
                    id="search-labs"
                    v-model="filterOptions"
                    type="search"
                    :placeholder="this.$i18n.t('admin.bussiness.searchProduct')"
                    class="custom-input"
                  >
                  </b-form-input>
                </b-input-group>
              </div>
              <hr />
            </div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-group-products"
                v-model="formPrograms.productos"
                :aria-describedby="ariaDescribedby"
                :options="optionsCategoryItem"
                name="optionsPrograms"
                stacked
                class="text-left custom-card-category"
              >
              </b-form-checkbox-group>
              <b-alert show v-if="optionsCategoryItem.length === 0">{{
                $t('admin.bussiness.msgSelectCountry')
              }}</b-alert>
              <span v-if="isLoadingPrograms"
                ><b-spinner small class="align-middle"></b-spinner>
                {{ $t('admin.medicines.loading') }}
              </span>
            </b-form-group>
          </b-card-text>
        </b-card>
        <hr />
      </section>

      <hr />

      <template #modal-footer>
        <b-row>
          <b-col class="justify-content-end buttons-dropdown">
            <b-button class="btn btn-blue-dark-outline mr-3" @click="handleCloseModal">
              {{ $t('admin.users.cancel') }}
            </b-button>
            <b-button class="btn btn-blue-dark-outline mr-3" @click="clearProgramForm" v-if="!isEdit">
              {{ $t('admin.bussiness.clear') }}
            </b-button>
            <b-button class="btn btn-blue-dark ml-0" @click="handleConfig" :disabled="isLoadingDone">
              <span v-if="!isLoadingDone">
                <span v-if="isEdit">{{ $t('admin.role.edit') }}</span>
                <span v-else>{{ $t('admin.role.create') }}</span>
                <b-icon icon="arrow-right" class="icon ml-2" aria-hidden="true"></b-icon>
              </span>
              <span v-else
                ><b-spinner small class="align-middle"></b-spinner>
                {{ $t('admin.medicines.loading') }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { API_ROOT } from '../../../Constants/Api';
import { AlertForm, ICON } from '../../../helpers/Alert';
import { ApiRequest } from '../../../helpers/ApiRequest';

export default {
  name: 'FormAgregarProductoRegenera',
  components: {},
  props: ['modalShow', 'getListConfiguration', 'isEdit', 'configurationForm', 'isClone'],
  data: () => ({
    isLoadingDone: false,
    isLoadingPrograms: false,

    formPrograms: {
      pais: 0,
      nombre: "",
      idProducto:"",
      status:0,
      bitRegenera:null,
      categorias:null
    },
    // options
    optionsSucursal: [],
    optionsCategories:[],
    optionsCountry: [
      { text: 'USA', value: 2 },
      { text: 'Mexico', value: 1 },
    ],
    selectedCategories: {},
    selectedCountry: {},
    allProducts: [],
    allProductsFilter:[],
    loadingBranch: false,
    optionsInternoProgram: [],
    allOptionsCategoryItem: [],
    optionsCategoryItem: [],
    optionsFilterProperty: [],
    optionsProperty: [],
    filterProperty: '',
    filterOptions: '',
  }),
  async mounted() {
    try {
      let data = await ApiRequest.get(`${API_ROOT}pipelines/propsContact`);
      this.optionsProperty = data.data;
      this.optionsFilterProperty = data.data;


    } catch (err) {
      AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.bussiness.errorListPipelines'), ICON.ERROR);
    }
  },
  computed: {
    pais() {
      return this.formPrograms.pais;
    },
    categorias() {
      return this.formPrograms.categorias;
    },
    validateForm() {
      return ( true );
    },
    placeholderText() {
      return this.$i18n.t('welcome.search');
    },
    placeholderProperty() {
      return this.$i18n.t('admin.bussiness.PropertyValue');
    },
    textDropdown() {
      return this.$i18n.t('admin.pharmacy.select');
    },
    pharmacySelected() {
      return this.$store.state.pharmacy_selected;
    },
  },
  watch: {
    configurationForm() {
      if (this.configurationForm) {
        this.formPrograms = {
          nombre: this.configurationForm.productos,
          idProducto: this.configurationForm._id,
          status: this.configurationForm.estado,
          bitRegenera: true,
        };
      }
    },
    async pais() {
      if (this.pais) {
        if (!(this.isEdit || this.isClone)) {
          this.formPrograms.productos = [];
          this.allProducts = []
          this.optionsCategories =[]
        } 
        await this.getProductsXCountry(this.pais,this.formPrograms.categorias);

      }
    },
    async categorias() {
      if (this.formPrograms.categorias) {
        if (!(this.isEdit || this.isClone)) {
          this.formPrograms.productos = [];
          this.allProducts = []
        } 
        await this.getProductsXCountry(this.pais,this.formPrograms.categorias);

      }
    },
   filterOptions(value) {
      const allProducts = this.allProductsFilter.map((el) => ({ 
        text: el.CodigoAlterno + ' ' +'(' + el.Categoria.toLowerCase() + ')', 
        value: el.prod_id, 
      }));

      if (value.length === 0) this.optionsCategoryItem = allProducts;
      
      this.optionsCategoryItem = allProducts.filter((el) => {
        return el.text.toLowerCase().includes(value.toLowerCase())
      });
    },
    filterProperty() {
      this.optionsFilterProperty = this.optionsProperty.filter((el) =>
        el.label.toLowerCase().includes(this.filterProperty)
      );
    },
  },

  methods: {
    async handleConfig() {
      this.isLoadingDone = true;
      const { productos: productsId } = this.formPrograms
      let selectedProducts = this.allProducts.filter(({ prod_id: productId }) => {
        return productsId.includes(productId)
      })
      selectedProducts = selectedProducts.map(prod => ({
        idProducto: prod.prod_id,
        nombre: prod.CodigoAlterno + ' ' +'(' + prod.Categoria.toLowerCase() + ')',
        categoria: prod.Categoria.toLowerCase(),
        status: 1,
        bitRegenera: true
      }))
      

      try {
        const URL_CREATE_REGENERA_PRODUCTS = `${API_ROOT}productos/createProductosRegenera`
        const { ok } = await ApiRequest.post(URL_CREATE_REGENERA_PRODUCTS, {
          productos: selectedProducts
        })
        
        if(!ok) throw new Error('Error creating regenera products')

        this.handleCloseModal()
        AlertForm(this.$i18n.t("admin.bussiness.createdRegeneraProduct"), "", ICON.SUCCESS);
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.top.errorCreatingRegeneraProducts'), ICON.ERROR);
      } finally {
        this.isLoadingDone = false;
      }
    },
    async getUniqueCategories(products) {
      return [...new Set(products.map(product => product.Categoria))];
    },
    async getProductsxCategories(products,categories) {
       if (!categories || categories.length === 0) {
        return products;
       }

      return products.filter(product => categories.includes(product.Categoria));
    },

    async getProductsXCountry(pais, categoria) {
      if(pais === 1) {
        this.selectedCountry = { text: 'Mexico', value: 1 }
      } else {
        this.selectedCountry = { text: 'USA', value: 2 }
      }
      
      this.isLoadingPrograms = true;
      this.optionsCategoryItem = [];
      const URL_PRODUCTS = `${API_ROOT}/productos/productosCentral/${pais}`
      
      try {
        const res = await ApiRequest.get(URL_PRODUCTS)
        let productos = res.data;

        let medicines;
        if (this.pharmacySelected.value) {
          const URL_MEDICINES = `${API_ROOT}filtrosMedicamentos/medicamentosIndividuales`
          const { data:medicinesData } =await ApiRequest.post(URL_MEDICINES, this.pharmacySelected.value);
          medicines = medicinesData;

          if(medicines.length > 0) productos = [...productos, ...medicines]
        }
        
        productos.sort((a, b) => a.CodigoAlterno.localeCompare(b.CodigoAlterno));

        this.allProducts = productos;
        this.optionsCategories =await this.getUniqueCategories(this.allProducts);
        this.allProductsFilter = await this.getProductsxCategories(this.allProducts,categoria);
        
        let valueField = 'prod_id';
        this.optionsCategoryItem = this.allProductsFilter.map((el) => {
            return {
                text: el.CodigoAlterno + ' ' +'(' + el.Categoria.toLowerCase() + ')',
                value: el[valueField]
            };
        });
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.top.errorProducts'), ICON.ERROR);
      } finally {
        this.isLoadingPrograms = false;
      }
    },
    
    addPropertyProgram() {
    },
    deletePropertyProgram(index) {
      this.formPrograms.reglas_productos.splice(index, 1);
      this.optionsInternoProgram.splice(index, 1);
    },

    propertyProgramsSelected(item, condition, index) {
      condition.opcion = [];

      let dataOption = item.options.map((el) => ({
        text: el.value,
        value: el.value,
      }));
      
      condition.info_interno = item;
      condition.nombre_interno = item.name;
      
      if (dataOption.length === 0) condition.opcion = [];
      
      this.optionsInternoProgram.splice(index, 1, dataOption);
    },
    handleCloseModal() {
      this.clearProgramForm();
      this.$emit('closeModal');
    },
    /* limpiar el form */
    clearProgramForm() {
      this.formPrograms = {
        pais: 0,
        categoria:null,
        nombre: null,
        estado: true,
        reglas_productos: [
          {
            nombre_interno: null,
            opcion: [],
            info_interno: null,
            value: null,
          },
        ],
        reglas_pdf: {
          principal: null,
          opcional: null,
        },
        productos: [],
      };
      this.allProducts = [];
      this.optionsCategoryItem = [];
      this.optionsInternoProgram = [];
      this.optionsCategories=[]
      
    },
  },
};
</script>

<style scoped>
@import '../../../assets/css/global.css';

.custom-actions {
  cursor: pointer;
}

.custom-card-category {
  max-height: 250px;
  overflow-y: auto;
}

.custom-buttom:disabled {
  cursor: not-allowed;
}

.config-bussiness /deep/ .btn-group {
  width: 100%;
}
.config-bussiness /deep/ .btn-group > button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.config-bussiness /deep/ .dropdown-menu.show {
  width: 100% !important;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.9rem;
}

.groupSearch {
  padding: 0 1rem;
}

.icon_link {
  cursor: pointer;
  opacity: 0.75;
}
/* delete */
.delete-item:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

.rol .configuracion {
  margin-top: 4rem;
}

.rol /deep/ .headerClass {
  color: var(--gray-color);
  font-size: 0.8rem;
}

.modal-config label {
  font-weight: bold;
  margin-top: 1rem;
}
</style>